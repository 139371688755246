// import { graphql } from 'gatsby'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import SEO from 'components/Meta'

import Layout from 'components/Layout'
import ControlledCarousel from 'components/Carousel'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import StepBox from 'components/StepBox/index';

const IndexPage = ({ intl }) => {

    return (
      <Layout > 

        <SEO
          site={siteMetadata}
          title="CIGAM GLOBAL - APPLY TO YOUR DREAM UNIVERSITY"
          description="CIGAM provides consultation and coaching services for students who are interested in studying and living abroad."
          
           />

        <div>
          
          <ControlledCarousel />
          
          <section className="text-center main">
            <div className="container containerCustom mt-5">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="section-heading">
                  <strong>C</strong>HANGE <strong>I</strong>NSPIRATION <strong>G</strong>OODNESS <strong>A</strong>SPIRATION <strong>M</strong>E
                  </h2>
                  <h1 className="">
                  <FormattedMessage id="cigam_provides" />
                  </h1>
                  <hr className="custom_hr" />
                </div>
              </div>
            </div>

            <div className="container m-r-auto m-l-auto">
              <div id="boxHeading">
                <p><FormattedHTMLMessage id="steps_h" /></p>
        <br />
              </div>

              <div className="row align-items-center justify-content-center">
                <StepBox id="step_1" bg="bgGrayDark" />
                <StepBox id="step_2" bg="bgGrayLight"/>
                <StepBox id="step_3" bg="bgGrayDark" />
                <StepBox id="step_4" bg="bgGrayLight"/>
                <StepBox id="step_5" bg="bgGrayDark" />
                <StepBox id="step_6" bg="bgGrayDark" />
                <StepBox id="step_7" bg="bgGrayLight"/>
                <StepBox id="step_8" bg="bgGrayDark" />
              </div>
            </div>
          </section>

        </div>
      </Layout>
    )
  }


export default injectIntl(IndexPage)

//export const query = graphql
