import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
import './style.scss'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import Image1 from 'components/CarouselImagesComponents/img1'
import Image2 from 'components/CarouselImagesComponents/img2'
import Image3 from 'components/CarouselImagesComponents/img3'
import Image4 from 'components/CarouselImagesComponents/img4'

class ControlledCarousel extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleSelect = this.handleSelect.bind(this)

    this.state = {
      index: 0,
      direction: null,
    }
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    })
  }

  render() {
    const { index, direction } = this.state

    return (
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}>

        <Carousel.Item>
          <div className="imgWrapper">
            <Image1 />
          </div>
  
          <Carousel.Caption>
            <p> <FormattedMessage id="caption_start_today" /></p>
            <h3>
            <FormattedMessage id="caption_apply_university" />
            </h3>
            <Button variant="warning warningCustom"><Link to="/services">
            <FormattedMessage id="button_learn_more" /></Link></Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="imgWrapper">
            <Image2 />
          </div>

          <Carousel.Caption>
            <p><FormattedMessage id="caption_we_understand" /></p>
            <h3>
            <FormattedMessage id="caption_students" />
            </h3>
            <Button variant="warning warningCustom"><Link to="/about">
            <FormattedMessage id="button_about_us" /></Link></Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="imgWrapper">
            <Image3 />
          </div>

          <Carousel.Caption>
            <p><FormattedMessage id="caption_concerns" /></p>
            <h3>
            <FormattedMessage id="caption_look_together" />
            </h3>
            <Button variant="warning warningCustom"><Link to="/contact-us">
            <FormattedMessage id="button_contact_us" /></Link></Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="imgWrapper">
            <Image4 />
          </div>

          <Carousel.Caption>
            <p><FormattedMessage id="caption_worreid" /></p>
            <h3>
            <FormattedMessage id="caption_help" />
            </h3>
            <Button variant="warning warningCustom"><Link to="/contact-us">
            <FormattedMessage id="button_contact_us" /></Link></Button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    )
  }
}
export default ControlledCarousel
