import React from 'react'
import './style.scss'

import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const StepBox = ({ id, bg }) => (
    

    <div className="col-lg-3 col-6">
        <div className={'stepBox ' + bg}>
            <div className="boxText">
                <FormattedMessage id={id} />
                <Link to={'/services/#'+id}>
                    <span className="link-spanner"></span>
                </Link>
            </div>
        </div>
    </div>
);

export default injectIntl(StepBox)